<template>
  <div class="min-h-screen bg-gradient-to-b from-[var(--primary-light)] to-[var(--secondary-light)] text-gray-800 flex flex-col justify-between">
    <!-- Header Component -->
    <HeaderComponent class="sticky top-0 z-50 bg-[var(--primary-light)] shadow-lg" />

    <!-- Section Héros -->
    <header class="bg-gradient-to-r from-[var(--primary)] to-[var(--secondary)] text-white p-16 text-center relative overflow-hidden rounded-b-3xl shadow-xl bg-cover bg-center bg-[var(--primary-light)]" style="background-image: url('https://example.com/hero-bg.jpg'); background-attachment: fixed;">
      <div class="absolute inset-0 opacity-30 bg-cover bg-center pointer-events-none" style="background-image: url('https://example.com/hero-bg.jpg');"></div>
      <div class="relative z-10">
        <h1 class="text-5xl font-extrabold mb-6">Découvrez la joie de la location de jouets !</h1>
        <p class="text-xl mb-8">Amusement illimité pour vos enfants, sans l'encombrement. Louez des jouets facilement et à un prix abordable.</p>
        <a href="/toys" class="mt-6 px-8 py-4 bg-primary-light hover:bg-secondary-light rounded-full font-semibold text-gray-900 transition duration-200 shadow-lg transform hover:scale-105">
          Commencez à louer maintenant
        </a>
      </div>
    </header>

    <!-- Section Caractéristiques -->
    <section class="py-20 bg-white">
      <div class="max-w-6xl mx-auto px-4 md:px-8">
        <h2 class="text-4xl font-bold text-center mb-12">Pourquoi louer chez nous ?</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div class="bg-[var(--primary-light)] p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <div class="flex items-center justify-center mb-6">
              <img src="@/assets/icons/affordable_plans_icon_20x20.webp" alt="Icône Plans abordables" class="w-20 h-20">
            </div>
            <h3 class="text-2xl font-semibold mb-4">Plans abordables</h3>
            <p>Choisissez parmi une variété de plans de location abordables qui répondent aux besoins de votre famille.</p>
          </div>
          <div class="bg-primary-light p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <div class="flex items-center justify-center mb-6">
              <img src="@/assets/icons/large_selection_icon_20X20.webp" alt="Icône Large sélection" class="w-20 h-20">
            </div>
            <h3 class="text-2xl font-semibold mb-4">Large sélection</h3>
            <p>Accédez à une grande variété de jouets, des ensembles éducatifs aux dernières figurines d'action.</p>
          </div>
          <div class="bg-primary-light p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <div class="flex items-center justify-center mb-6">
              <img src="@/assets/icons/ecological_toys_20X20.webp" alt="Icône Amusement durable" class="w-20 h-20">
            </div>
            <h3 class="text-2xl font-semibold mb-4">Amusement durable</h3>
            <p>Réduisez les déchets et gagnez de la place en louant plutôt qu'en achetant.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Section Suggestions Actuelles -->
    <section class="bg-gradient-to-b from-[var(--primary-light)] to-[var(--secondary-light)] py-20">
      <div class="max-w-6xl mx-auto px-4 md:px-8">
        <h2 class="text-4xl font-bold text-center mb-12">Suggestions Actuelles</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div class="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <div class="flex items-center mb-6">
              <img src="@/assets/icons/girl.webp" alt="Sarah T." class="w-16 h-16 rounded-full mr-4">
              <p class="font-semibold text-lg">Sarah T.</p>
            </div>
            <p class="mb-4">"La location de jouets a été incroyable pour mes enfants. Ils peuvent jouer avec de nouveaux jouets chaque mois, et je n'ai pas à me soucier de l'encombrement !"</p>
          </div>
          <div class="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <div class="flex items-center mb-6">
              <img src="@/assets/icons/boy.webp" alt="John K." class="w-16 h-16 rounded-full mr-4">
              <p class="font-semibold text-lg">John K.</p>
            </div>
            <p class="mb-4">"J'adore la simplicité de cette plateforme. La sélection de jouets est fantastique, et c'est super abordable."</p>
          </div>
        </div>
        <div class="text-center mt-12">
          <a href="/toys" class="px-8 py-4 bg-[var(--primary)] hover:bg-[var(--secondary)] text-white rounded-full font-semibold shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">Voir plus de suggestions</a>
        </div>
      </div>
    </section>

    <!-- Section Appel à l'action -->
    <section class="py-20 text-center bg-gradient-to-r from-[var(--secondary)] to-[var(--primary)] text-gray-900 rounded-t-3xl shadow-inner">
      <h2 class="text-4xl font-bold mb-6">Prêt à commencer ?</h2>
      <p class="mb-8 text-lg">Offrez à vos enfants la joie de nouveaux jouets chaque mois, sans engagement.</p>
      <a href="/sign-in-up" class="px-8 py-4 bg-white hover:bg-gray-100 rounded-full font-semibold text-[var(--secondary)] transition duration-200 shadow-lg transform hover:scale-105">
        Inscrivez-vous aujourd'hui
      </a>
    </section>

    <!-- Section Pied de page -->
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from './shared/FooterComponent.vue';
import HeaderComponent from './shared/HeaderComponent.vue';

export default {
  name: 'LandingPage',
  components: { HeaderComponent, FooterComponent },
};
</script>

<style scoped>
.header-sticky {
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: var(--primary-light);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.header-sticky {
  position: sticky;
  top: 0;
  z-index: 50;
  background: var(--primary-light);
}
:root {
  --primary: #f97316; /* Orange */
  --secondary: #10b981; /* Green */
  --primary-light: #fde68a; /* Light Orange */
  --secondary-light: #a7f3d0; /* Light Green */
}

.bg-primary {
  background-color: var(--primary);
}
.bg-secondary {
  background-color: var(--secondary);
}
.bg-primary-light {
  background-color: var(--primary-light);
}
.bg-secondary-light {
  background-color: var(--secondary-light);
}
.text-primary {
  color: var(--primary);
}
.text-secondary {
  color: var(--secondary);
}
</style>
