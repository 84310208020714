import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../components/LandingPage.vue';
import ToyListPage from '../components/views/ToyListPage.vue';
import ToyDetailPage from '../components/ToyDetailPage.vue';
import PaymentPage from '../components/PaymentPage.vue';
import RentalSuccessPage from '../components/RentalSuccessPage.vue';
import SignInUpPage from '../components/views/SignInUpPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage,
  },
  {
    path: '/toys',
    name: 'Toys',
    component: ToyListPage,
  },
  {
    path: '/toy/:id',
    name: 'ToyDetail',
    component: ToyDetailPage
  },
  {
    path: '/payment',
    name: 'PaymentPage',
    component: PaymentPage
  },
  {
    path: '/rental-success',
    name: 'RentalSuccess',
    component: RentalSuccessPage
  },
  {
    path: '/sign-in-up',
    name: 'SignInUp',
    component: SignInUpPage
  }
  
  // Ajoutez d'autres routes ici si nécessaire
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
