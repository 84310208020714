<template>
    <!-- Section d'en-tête -->
    <header class="bg-gradient-to-b from-primary-light to-secondary-light shadow-lg py-4 sticky top-0 z-50">
      <div class="max-w-6xl mx-auto px-4 md:px-8 flex justify-between items-center">
        <h1 class="text-3xl font-bold text-primary">Prince des jouets</h1>
        <nav class="flex items-center space-x-6">
          <ul class="flex space-x-6">
            <li><a href="/" class="text-gray-700 hover:text-primary transition duration-200">Accueil</a></li>
            <li class="relative group">
              <a href="#" class="text-gray-700 hover:text-primary transition duration-200">Nos produits</a>
              <ul class="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-lg hidden group-hover:block transition duration-200 z-50">
                <li><a href="/toys" class="block px-4 py-2 text-gray-700 hover:bg-primary-light">Meilleur locations</a></li>
                <li><a href="/toys" class="block px-4 py-2 text-gray-700 hover:bg-secondary-light">Dernières nouveautés</a></li>
                <li><a href="/toys" class="block px-4 py-2 text-gray-700 hover:bg-secondary-light">Promotions</a></li>
                <li><a href="/toys" class="block px-4 py-2 text-gray-700 hover:bg-secondary-light">Tous</a></li>
              </ul>
            </li>
            <li><a href="/toys" class="text-gray-700 hover:text-primary transition duration-200">Suggestions Actuelles</a></li>
            <li class="relative group">
              <a href="/toys" class="text-gray-700 hover:text-primary transition duration-200">Jouet à vendre</a>
              <ul class="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-lg hidden group-hover:block transition duration-200 z-50">
                <li><a href="/toys" class="block px-4 py-2 text-gray-700 hover:bg-secondary-light">Liste</a></li>
                <li><a href="/toys" class="block px-4 py-2 text-gray-700 hover:bg-secondary-light">Carte</a></li>
              </ul>
            </li>
          </ul>
          <div class="flex space-x-4">
            <a href="/sign-in-up" class="text-gray-700 hover:text-primary transition duration-200 font-semibold">Se connecter</a>
            <a href="/sign-in-up" class="px-4 py-2 bg-primary text-white rounded-full hover:bg-secondary transition duration-200 font-semibold">S'inscrire</a>
          </div>
        </nav>
      </div>
    </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
};
</script>

<style scoped>
.bg-primary {
  background-color: var(--primary);
}
.bg-secondary {
  background-color: var(--secondary);
}
.bg-primary-light {
  background-color: var(--primary-light);
}
.bg-secondary-light {
  background-color: var(--secondary-light);
}
.text-primary {
  color: var(--primary);
}
.text-secondary {
  color: var(--secondary);
}
</style>
