<template>
  <div class="bg-orange-50 p-8 rounded-2xl shadow-lg mb-12">
    <h3 class="text-2xl font-bold text-green-600 mb-6">Adresse</h3>
    <form @submit.prevent="handleAddressSubmit">
      <div class="mb-4">
        <label for="address" class="block text-lg font-semibold text-gray-700 mb-2">Adresse de livraison</label>
        <input type="text" id="address" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
      </div>
      <div class="flex space-x-4">
        <div class="w-1/2">
          <label for="zip" class="block text-lg font-semibold text-gray-700 mb-2">Code Postal</label>
          <input type="text" id="zip" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
        </div>
        <div class="w-1/2">
          <label for="city" class="block text-lg font-semibold text-gray-700 mb-2">Ville</label>
          <input type="text" id="city" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
        </div>
      </div>
      <div class="text-center mt-8">
        <button type="submit" class="px-8 py-4 bg-gradient-to-r from-green-500 to-orange-500 text-white rounded-full shadow-lg hover:shadow-xl transition duration-300 font-bold">Valider l'Adresse</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'AddressDetailsComponent',
  methods: {
    handleAddressSubmit() {
      // Add address submission logic here
      console.log('Address form submitted');
    }
  }
};
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés supplémentaires */
</style>
