<template>
  <div class="min-h-screen bg-gradient-to-b from-green-200 to-orange-200 text-gray-800 flex flex-col justify-between">
    <!-- Header Component -->
    <HeaderComponent />

    <div class="flex-grow flex flex-col justify-center items-center">
      <div class="bg-white p-10 rounded-2xl shadow-lg text-center">
        <h1 class="text-4xl font-bold text-green-600 mb-6">Félicitations !</h1>
        <p class="text-lg text-gray-700 mb-8">Votre location a été réussie. Merci d'avoir choisi Prince des jouets pour votre plaisir de jouer !</p>
        <img src="https://example.com/success-image.jpg" alt="Succès" class="w-48 h-48 object-cover rounded-full mx-auto mb-8">
        <p class="text-md text-gray-600 mb-4">Vous recevrez un e-mail de confirmation avec tous les détails de votre location.</p>
        <router-link to="/" class="px-8 py-4 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full shadow-lg hover:shadow-xl transition duration-300 font-bold">Retour à l'accueil</router-link>
      </div>
    </div>

    <!-- Footer Component -->
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './shared/HeaderComponent.vue';
import FooterComponent from './shared/FooterComponent.vue';

export default {
  name: 'RentalSuccessPage',
  components: {
    HeaderComponent,
    FooterComponent
  }
};
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés supplémentaires */
</style>
