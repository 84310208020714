<template>
  <footer class="bg-green-500 text-white py-10 mt-auto">
    <div class="max-w-6xl mx-auto px-4 md:px-8 text-center">
      <p class="mb-6 text-lg">&copy; 2024 Prince des jouets. Tous droits réservés.</p>
      <ul class="flex justify-center space-x-8 mb-6">
        <li><a href="#" class="hover:text-orange-300 transition duration-300">Politique de confidentialité</a></li>
        <li><a href="#" class="hover:text-orange-300 transition duration-300">Conditions d'utilisation</a></li>
        <li><a href="#" class="hover:text-orange-300 transition duration-300">Contactez-nous</a></li>
      </ul>
      <p class="mb-4 text-lg">Suivez-nous sur :</p>
      <div class="flex justify-center space-x-6">
        <a href="#" class="hover:text-orange-300 transition duration-300">
          <i class="fab fa-facebook-f"></i>
        </a>
        <a href="#" class="hover:text-orange-300 transition duration-300">
          <i class="fab fa-twitter"></i>
        </a>
        <a href="#" class="hover:text-orange-300 transition duration-300">
          <i class="fab fa-instagram"></i>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
};
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés supplémentaires */
</style>
