<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Ajoutez vos styles globaux ici si nécessaire */
</style>
