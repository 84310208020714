<template>
  <div class="min-h-screen bg-gradient-to-b from-orange-200 to-green-200 text-gray-800 flex flex-col justify-between">
    <!-- Section d'en-tête -->
    <HeaderComponent />

    <section class="py-20 bg-white">
<div class="max-w-6xl mx-auto px-4 md:px-8 flex flex-col lg:flex-row gap-12">
        <!-- Section détails du jouet -->
        <div class="w-full lg:w-1/3 order-2 lg:order-1">
          <img src="@/assets/icons/educational_toy.webp" alt="Jouet en détail" class="w-full h-96 object-cover rounded-lg mb-6 shadow-md">
          <h2 class="text-4xl font-bold text-green-600 mb-4">Jouet éducatif - Détails complets</h2>
          <p class="text-lg text-gray-700 mb-6 leading-relaxed">Ce jouet éducatif est parfait pour aider les enfants à apprendre et à grandir. Conçu pour les enfants de 3 à 5 ans, ce jouet permet de développer leurs compétences cognitives et leur créativité tout en s'amusant.</p>
          <p class="text-3xl font-bold text-orange-600 mb-6">15 € / semaine</p>
          <a href="/payment" class="w-full px-6 py-3 bg-gradient-to-r from-green-500 to-orange-500 text-white rounded-full shadow-lg hover:shadow-xl transition duration-300 font-bold">Louer maintenant</a>
        </div>
<!-- Section calendrier de location -->
        <div class="w-full lg:w-2/3 bg-orange-50 p-8 rounded-2xl shadow-lg order-1 lg:order-2">
          <h3 class="text-2xl font-bold text-orange-600 mb-6">Calendrier de location</h3>
          <p class="text-md text-gray-700 mb-6">Sélectionnez les jours que vous souhaitez louer :</p>
          <div id="calendar" class="bg-white p-4 rounded-lg shadow-md">
            <vue-cal
              locale="fr"
              :view="'month'"
              :time="false"
              active-view="month"
              :disable-views="['years', 'year', 'week', 'day']"
              :min-view="'day'"
              :events="events"
              @cell-click="handleDaySelect"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Section Pied de page -->
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from './shared/HeaderComponent.vue';
import FooterComponent from './shared/FooterComponent.vue';

import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

export default {
  name: 'ToyDetailPage',
  components: { HeaderComponent, VueCal, FooterComponent },
  data() {
    return {
      events: [] // Placeholder for events if needed
    };
  },
  methods: {
    handleDaySelect(event) {
      console.log('Selected day:', event);
      // Handle day selection logic here
    }
  }
};
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés supplémentaires */
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
</style>
