
<template>
  <div class="bg-white p-8 rounded-2xl shadow-lg">
    <h3 class="text-2xl font-bold text-green-600 mb-6">Détails du Paiement</h3>
    <form @submit.prevent="handlePaymentSubmit">
      <div class="mb-4">
        <label for="card-number" class="block text-lg font-semibold text-gray-700 mb-2">Numéro de Carte</label>
        <input type="text" id="card-number" placeholder="1234 5678 9012 3456" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
      </div>
      <div class="flex space-x-4 mb-4">
        <div class="w-1/2">
          <label for="expiry-date" class="block text-lg font-semibold text-gray-700 mb-2">Date d'expiration</label>
          <input type="text" id="expiry-date" placeholder="MM/AA" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
        </div>
        <div class="w-1/2">
          <label for="cvv" class="block text-lg font-semibold text-gray-700 mb-2">CVV</label>
          <input type="text" id="cvv" placeholder="123" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
        </div>
      </div>
      <div class="mb-4">
        <label for="card-holder" class="block text-lg font-semibold text-gray-700 mb-2">Titulaire de la carte</label>
        <input type="text" id="card-holder" placeholder="Nom du titulaire de la carte" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
      </div>
      <div class="text-center mt-8">
        <a href="/rental-success" type="submit" class="px-8 py-4 bg-gradient-to-r from-green-500 to-orange-500 text-white rounded-full shadow-lg hover:shadow-xl transition duration-300 font-bold">Valider le Paiement</a>
      </div>
    </form>

    <!-- Logos des méthodes de paiement -->
    <div class="flex space-x-4 mt-6">
      <img src="https://example.com/visa-logo.png" alt="Visa" class="h-10">
      <img src="https://example.com/mastercard-logo.png" alt="Mastercard" class="h-10">
      <img src="https://example.com/paypal-logo.png" alt="PayPal" class="h-10">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentDetailsComponent',
  methods: {
    handlePaymentSubmit() {
      // Add payment submission logic here
      console.log('Payment form submitted');
    }
  }
};
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés supplémentaires */
</style>
