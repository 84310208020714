<template>
  <div class="min-h-screen bg-gradient-to-b from-orange-200 to-green-200 text-gray-800 flex flex-col justify-between">
    <!-- Section d'en-tête -->
    <HeaderComponent />

    <section class="py-20 bg-white">
      <div class="max-w-6xl mx-auto px-4 md:px-8">
        <div>
          <h2 class="text-4xl font-bold text-center text-orange-600 mb-12">Liste des jouets</h2>
          <div class="flex flex-wrap items-center space-x-4 mb-6">
            <div>
              <label for="sort" class="block text-orange-600 font-semibold mb-2">Trier par</label>
              <select id="sort" @change="sortToys" class="border-2 border-orange-400 rounded-lg px-4 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-orange-500">
                <option value="">Aucun tri</option>
                <option value="price-asc">Prix croissant</option>
                <option value="price-desc">Prix décroissant</option>
                <option value="alphabetical-asc">Ordre alphabétique (A-Z)</option>
                <option value="alphabetical-desc">Ordre alphabétique (Z-A)</option>
              </select>
            </div>
            <div>
              <label for="age" class="block text-green-600 font-semibold mb-2">Âge</label>
              <select id="age" class="border-2 border-green-400 rounded-lg px-4 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-green-500">
                <option value="">Tous les âges</option>
                <option value="0-2">0-2 ans</option>
                <option value="3-5">3-5 ans</option>
                <option value="6-8">6-8 ans</option>
                <option value="9+">9 ans et plus</option>
              </select>
            </div>
            <div>
              <label for="price" class="block text-green-600 font-semibold mb-2">Prix</label>
              <select id="price" class="border-2 border-green-400 rounded-lg px-4 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-green-500">
                <option value="">Tous les prix</option>
                <option value="0-20">0-20 €</option>
                <option value="20-50">20-50 €</option>
                <option value="50+">50 € et plus</option>
              </select>
            </div>
            <button class="px-6 py-3 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full shadow-md hover:bg-purple-500 hover:shadow-lg transition duration-300 mt-6 md:mt-0">Appliquer les filtres</button>
          </div>
        </div>

        <!-- Liste des jouets -->
        <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
          <div class="bg-orange-50 p-6 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <img src="@/assets/icons/educational_toy.webp" alt="Jouet éducatif" class="w-full h-48 object-cover rounded-lg mb-4">
            <h3 class="text-2xl font-bold text-orange-600 mb-2">Jouet éducatif</h3>
            <p class="text-lg font-bold text-green-600 mb-4">15 € / mois</p>
            <a href="/toy/1" class="w-full px-4 py-2 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full font-bold shadow-md hover:shadow-lg transition duration-300">Louer maintenant</a>
          </div>
          <div class="bg-green-50 p-6 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <img src="@/assets/icons/action_toy.webp" alt="Figurine d'action" class="w-full h-48 object-cover rounded-lg mb-4">
            <h3 class="text-2xl font-bold text-green-600 mb-2">Figurine d'action</h3>
            <p class="text-lg font-bold text-orange-600 mb-4">25 € / mois</p>
            <a href="/toy/1" class="w-full px-4 py-2 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full font-bold shadow-md hover:shadow-lg transition duration-300">Louer maintenant</a>
          </div>
          <div class="bg-green-50 p-6 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <img src="@/assets/icons/construction_toy.webp" alt="Jeu de construction" class="w-full h-48 object-cover rounded-lg mb-4">
            <h3 class="text-2xl font-bold text-green-600 mb-2">Jeu de construction</h3>
            <p class="text-lg font-bold text-orange-600 mb-4">30 € / mois</p>
            <a href="/toy/1" class="w-full px-4 py-2 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full font-bold shadow-md hover:shadow-lg transition duration-300">Louer maintenant</a>
          </div>
          <div class="bg-orange-50 p-6 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <img src="@/assets/icons/remote_car.webp" alt="Voiture téléguidée" class="w-full h-48 object-cover rounded-lg mb-4">
            <h3 class="text-2xl font-bold text-orange-600 mb-2">Voiture téléguidée</h3>
            <p class="text-lg font-bold text-green-600 mb-4">20 € / mois</p>
            <a href="/toy/1" class="w-full px-4 py-2 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full font-bold shadow-md hover:shadow-lg transition duration-300">Louer maintenant</a>
          </div>
          <div class="bg-orange-50 p-6 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <img src="@/assets/icons/painting_kit.webp" alt="Kit de peinture" class="w-full h-48 object-cover rounded-lg mb-4">
            <h3 class="text-2xl font-bold text-orange-600 mb-2">Kit de peinture</h3>
            <p class="text-lg font-bold text-green-600 mb-4">12 € / mois</p>
            <a href="/toy/1" class="w-full px-4 py-2 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full font-bold shadow-md hover:shadow-lg transition duration-300">Louer maintenant</a>
          </div>
          <div class="bg-green-50 p-6 rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <img src="@/assets/icons/robot.webp" alt="Robot interactif" class="w-full h-48 object-cover rounded-lg mb-4">
            <h3 class="text-2xl font-bold text-green-600 mb-2">Robot interactif</h3>
            <p class="text-lg font-bold text-orange-600 mb-4">35 € / mois</p>
            <a href="/toy/1" class="w-full px-4 py-2 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full font-bold shadow-md hover:shadow-lg transition duration-300">Louer maintenant</a>
          </div>
        </div>

        <!-- Pagination -->
        <div class="flex justify-center mt-12">
          <button class="px-4 py-2 mx-1 bg-orange-200 text-orange-600 rounded-full shadow-md hover:shadow-lg hover:bg-orange-300 transition duration-300">&laquo; Précédent</button>
          <button class="px-4 py-2 mx-1 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full shadow-md hover:shadow-lg transition duration-300">1</button>
          <button class="px-4 py-2 mx-1 bg-green-200 text-green-600 rounded-full shadow-md hover:shadow-lg hover:bg-green-300 transition duration-300">2</button>
          <button class="px-4 py-2 mx-1 bg-green-200 text-green-600 rounded-full shadow-md hover:shadow-lg hover:bg-green-300 transition duration-300">3</button>
          <button class="px-4 py-2 mx-1 bg-orange-200 text-orange-600 rounded-full shadow-md hover:shadow-lg hover:bg-orange-300 transition duration-300">Suivant &raquo;</button>
        </div>
      </div>
    </section>

    <!-- Section Pied de page -->
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '../shared/HeaderComponent.vue';
import FooterComponent from '../shared/FooterComponent.vue';

export default {
  name: 'ToyListPage',
  components: { HeaderComponent, FooterComponent },
  methods: {
    sortToys(event) {
      const sortValue = event.target.value;
      // Logic to sort the toys based on the selected value
      console.log('Selected sort option:', sortValue);
    }
  }
};
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés supplémentaires */
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
</style>
