<template>
  <div class="min-h-screen bg-gradient-to-b from-orange-200 to-green-200 text-gray-800 flex flex-col justify-between">
    <!-- Section d'en-tête -->
    <HeaderComponent :page-title="'Paiement'" />

    <section class="py-20 bg-white">
      <div class="max-w-4xl mx-auto px-4 md:px-8">
        <!-- Section Détails du Jouet et de la Location -->
        <h2 class="text-3xl font-bold text-orange-600 mb-6">Récapitulatif de la location</h2>
        <ToyDetailsComponent :toy-image-url="toyImageUrl" />

        <h2 class="text-4xl font-bold text-center text-orange-600 mb-12">Addresse de livraison</h2>

        <AddressDetailsComponent />

        <!-- Section Mode de Paiement -->
        <PaymentDetailsComponent />
      </div>
    </section>

    <!-- Section Pied de page -->
    <FooterComponent />
  </div>
</template>

<script>
import ToyDetailsComponent from './ToyDetailsComponent.vue';
import AddressDetailsComponent from './AddressDetailsComponent.vue';
import PaymentDetailsComponent from './PaymentDetailsComponent.vue';
import FooterComponent from './shared/FooterComponent.vue';
import HeaderComponent from './shared/HeaderComponent.vue';

export default {
  name: 'PaymentPage',
  components: { HeaderComponent, ToyDetailsComponent, AddressDetailsComponent, PaymentDetailsComponent, FooterComponent },
  data() {
    return {
      toyImageUrl: 'https://example.com/toy-thumbnail.jpg' // Replace with dynamic data as needed
    };
  },
  methods: {
    handleSubmit() {
      // Add form submission logic here
      console.log('Form submitted');
    }
  }
};
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés supplémentaires */
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
</style>
