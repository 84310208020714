<template>
  <div class="bg-orange-50 p-8 rounded-2xl shadow-lg mb-12 flex flex-col lg:flex-row items-center">
    <img src="@/assets/icons/educational_toy.webp" alt="Jouet" class="w-32 h-32 object-cover rounded-lg mb-6 lg:mb-0 lg:mr-8">
    <div>
      <h3 class="text-2xl font-bold text-green-600 mb-4">{{ toyName }}</h3>
      <div class="flex space-x-4">
        <div>
          <label class="block text-lg font-semibold text-gray-700 mb-2">Date de début :</label>
          <p class="text-md text-gray-800">{{ startDate }}</p>
        </div>
        <div>
          <label class="block text-lg font-semibold text-gray-700 mb-2">Date de fin :</label>
          <p class="text-md text-gray-800">{{ endDate }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToyDetailsComponent',
  props: {
    toyImageUrl: {
      type: String,
      default: 'https://example.com/toy-image.jpg',
      required: false
    },
    toyName: {
      type: String,
      default: 'Jouet Éducatif',
      required: false
    },
    startDate: {
      type: String,
      default: '01/01/2024',
      required: false
    },
    endDate: {
      type: String,
      default: '07/01/2024',
      required: false
    }
  }
};
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés supplémentaires */
</style>