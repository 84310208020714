<template>
  <div class="min-h-screen bg-gradient-to-b from-orange-200 to-green-200 text-gray-800 flex flex-col justify-between">
    <!-- Header Component -->
    <HeaderComponent />

    <div class="flex-grow flex flex-col justify-center items-center">
      <div class="bg-white p-10 rounded-2xl shadow-lg text-center w-full max-w-lg">
        <div class="mb-6 border-b-2 border-gray-200">
          <ul class="flex justify-center">
            <li @click="activeTab = 'login'" :class="{'border-b-4 border-orange-500': activeTab === 'login'}" class="cursor-pointer py-2 px-6 text-lg font-bold text-orange-600">Se connecter</li>
            <li @click="activeTab = 'signup'" :class="{'border-b-4 border-green-500': activeTab === 'signup'}" class="cursor-pointer py-2 px-6 text-lg font-bold text-green-600">S'inscrire</li>
          </ul>
        </div>

        <div v-if="activeTab === 'login'">
          <h1 class="text-4xl font-bold text-orange-600 mb-6">Se connecter</h1>
          <form @submit.prevent="handleLogin">
            <div class="mb-4">
              <input type="email" placeholder="Adresse e-mail" class="w-full border-2 border-orange-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500" required>
            </div>
            <div class="mb-4">
              <input type="password" placeholder="Mot de passe" class="w-full border-2 border-orange-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-orange-500" required>
            </div>
            <button type="submit" class="px-8 py-4 bg-gradient-to-r from-orange-500 to-green-500 text-white rounded-full shadow-lg hover:shadow-xl transition duration-300 font-bold">Se connecter</button>
          </form>
        </div>

        <div v-if="activeTab === 'signup'">
          <h1 class="text-4xl font-bold text-green-600 mb-6">S'inscrire</h1>
          <form @submit.prevent="handleSignup">
            <div class="mb-4">
              <input type="text" placeholder="Nom complet" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
            </div>
            <div class="mb-4">
              <input type="email" placeholder="Adresse e-mail" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
            </div>
            <div class="mb-4">
              <input type="password" placeholder="Mot de passe" class="w-full border-2 border-green-400 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-green-500" required>
            </div>
            <button type="submit" class="px-8 py-4 bg-gradient-to-r from-green-500 to-orange-500 text-white rounded-full shadow-lg hover:shadow-xl transition duration-300 font-bold">S'inscrire</button>
          </form>
        </div>
      </div>
    </div>

    <!-- Footer Component -->
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from '../shared/HeaderComponent.vue';
import FooterComponent from '../shared/FooterComponent.vue';

export default {
  name: 'LoginSignupPage',
  components: {
    HeaderComponent,
    FooterComponent
  },
  data() {
    return {
      activeTab: 'login'
    };
  },
  methods: {
    handleLogin() {
      // Add login logic here
      console.log('Login form submitted');
    },
    handleSignup() {
      // Add signup logic here
      console.log('Signup form submitted');
    }
  }
};
</script>

<style scoped>
/* Ajoutez ici des styles personnalisés supplémentaires */
</style>
